export default
`#### Climate change (Kg CO2 eq.)

We can obtain the *Climate change* from the following KPIs by multiplying them by the following conversion factors and then, sum all the results:

|                                           | Conversion factor|
|:------------------------------------------|-----------------:|
|**Biomass produced**(Tn) only for seabass  |                 -|
|**Biomass produced**(Tn) only for seabream |                 -|
|**Feed**(Tn):                              |               790|
|**Fuel, burned in vessel**(L):             |              3.09|
|**Electricity, european average**(Kwh):    |             0.427|

<br>
For example, we have:

- 1000 Tn of biomass produced of seabass.
- 2500 Tn of feed.
- 250 L Of fuel.
- 250 Kwh of electricity.

The operation will be:
$$
    1,000 + (2,500 \\cdot 790) + (250 \\cdot 3.09) + (250 \\cdot 0.427)
$$
$$
    = 1,000 + 1,975,000 + 772.5 + 106.75
$$
$$
    = 1,976,879.25
$$

<br>

*Sources* : MedAid project, Ecoinvent V3`;